import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { StateMachineProvider, createStore, setStorageType } from 'little-state-machine'
import { ReactQueryCacheProvider, QueryCache } from 'react-query'
import { DialogProvider } from 'muibox'
import { SnackbarProvider } from 'notistack'

import { App } from './App'
import { GlobalTheme } from './GlobalStyle'
import store from './store'
import { UpdateChecker } from './UpdatedChecker'
import { init } from '@qpixio/upload'
import { SendBirdMessagesChecker } from './components/custom/SendBirdChat/SendBirdMessagesChecker'

setStorageType(localStorage)
createStore(store)
export const queryCache = new QueryCache()

init({
  clevershopAPI: 'https://upload-dev.v1.api.estests.com/',
  // todo replace with token from tenet
  // ^^ yeah right
  token: process.env.REACT_APP_UPLOAD_TOKEN || 'E8DkRcSr5gCwvf399xKiA',
})

const snackbarOptions = {
  maxSnack: 4,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
}

ReactDOM.render(
  <StateMachineProvider>
    <GlobalTheme>
      <SnackbarProvider {...snackbarOptions}>
        <DialogProvider>
          <ReactQueryCacheProvider queryCache={queryCache}>
            <UpdateChecker>
              <SendBirdMessagesChecker>
                <Router>
                  <App />
                </Router>
              </SendBirdMessagesChecker>
            </UpdateChecker>
          </ReactQueryCacheProvider>
        </DialogProvider>
      </SnackbarProvider>
    </GlobalTheme>
  </StateMachineProvider>
  , document.getElementById('root'))
