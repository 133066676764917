import { path, pathOr } from 'ramda'
import { useMutation } from 'react-query'
import { useStateMachine } from 'little-state-machine'

import useRequest from './useRequest'
import useFields from './useFields'
import parseStructure from './parseStructure'
import * as gql from '../gql/queries'
import * as endpoints from '../config/endpoints'

export default function useUpload ({ entity, uploadFields = [] }) {
  const { state } = useStateMachine()
  const { request } = useRequest()

  const structure = parseStructure(state.structure)
  const isNewUpload = structure.upload.mutation.query === 'NEW_UPLOAD'

  const [upload] = useMutation((variables) => {
    return request({
      query: gql[structure.upload.mutation.query],
      variables,
      endpoint: endpoints[structure.upload.mutation.endpoint],
    })
  })

  const { replaceFields } = useFields()
  const paths = uploadFields.map((path) => path?.split('.'))
  const handleField = async (fields, value, entityId) => {
    if (!fields.length) {
      if (Array.isArray(value)) {
        const uploadData = await Promise.all(value.map(async (element) => (!element || typeof element === 'string')
          ? await new Promise((resolve) => resolve({ upload: [element] }))
          : await upload({ files: isNewUpload ? [element] : [{ file: element, entityId, entity }], type: 'default', token: process.env.REACT_APP_UPLOAD_TOKEN })))

        return uploadData.map((datum) => pathOr(path(['upload', '0'], datum), ['uploadFiles', 'urls', 0], datum))
      } else {
        const uploadData = (!value || typeof value === 'string')
          ? await new Promise((resolve) => resolve({ upload: [value] }))
          : await upload({ files: isNewUpload ? [value] : [{ file: value, entityId, entity }], type: 'default', token: process.env.REACT_APP_UPLOAD_TOKEN })

        return pathOr(path(['upload', '0'], uploadData), ['uploadFiles', 'urls', 0], uploadData)
      }
    }
    const fieldValue = value[fields[0]]
    if (!fieldValue) return value
    if (Array.isArray(fieldValue) && fields.length > 1) {
      return {
        ...value,
        [fields[0]]: await Promise.all(fieldValue.map((field, index) => handleField([index, ...fields.slice(1)], fieldValue, entityId))),
      }
    } else {
      if (Array.isArray(value)) return handleField(fields.slice(1), fieldValue, entityId)
      return { ...value, [fields[0]]: await handleField(fields.slice(1), fieldValue, entityId) }
    }
  }

  async function myUpload (passedValues, entityId) {
    const dataAfterUpload = await Promise.all(paths.map((path) => handleField(path, passedValues, entityId)))
    const data = paths.reduce((acc, path, index) => replaceFields(path, acc, dataAfterUpload[index]), passedValues)

    return data
  }

  return myUpload
}
